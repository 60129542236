<template>
  <div class="h-100">
    <AppLoading fillSpace v-if="loading"></AppLoading>
    <div v-else>
      <div class="d-none d-lg-block">
        <AppTable
          class="h-100"
          :headers="header"
          :items="items.data"
          :currentPage="items.current_page"
          :total="items.total"
          @pageChange="(page) => $emit('pageChange', page)"
          @sort="sortBy"
        >
          <template v-slot:item="{ shownItem }">
            <!-- RFQ Detail -->
            <td>{{ shownItem.id | rfqFormat }}</td>
            <!-- PO Number -->
            <td class="text-truncate" style="max-width: 150px">
              {{ shownItem.po_number || 'N/A' }}
            </td>
            <!-- Project Name -->
            <td class="text-truncate" style="max-width: 150px">
              {{ shownItem.project_name }}
            </td>
            <!-- Order Date -->
            <td
              v-if="
                status == STATUS.REQUEST_PENDING.value ||
                status == STATUS.REQUEST_APPROVED.value
              "
            >
              <span>{{ shownItem.created_at | readableDate(false) }}</span>
              <span class="ml-1 text-gray">
                {{ shownItem.created_at | readableTime }}
              </span>
            </td>
            <td v-else class="d-flex align-items-center">
              <img
                src="@/assets/pocketyard_logo_black.svg"
                class="rounded-circle mr-3"
                width="30"
                height="30"
              />
              <div style="width: 150px">
                <p class="m-0 text-truncate">
                  {{ shownItem.user.information | userFullName }}
                </p>
                <p class="m-0 text-gray text-truncate">
                  {{ shownItem.user.information | userCompany }}
                </p>
              </div>
            </td>
            <!-- Approved Date -->
            <td
              v-if="
                status == STATUS.QUOTE_APPROVED.value ||
                status === STATUS.COMPLETED.value
              "
            >
              {{ shownItem.updated_at | readableDate(false) }}
            </td>
            <!-- Bidding End Date -->
            <td v-else>
              <span>
                {{
                  shownItem.bidding_end_date
                    | readableDate(false, 'yyyy-MM-dd HH:mm:ss')
                }}
              </span>
              <span class="ml-1 d-inline-block text-gray">
                {{
                  shownItem.bidding_end_date
                    | readableTime('yyyy-MM-dd HH:mm:ss')
                }}
              </span>
            </td>
            <!-- Delivery Date -->
            <td>
              <span class="d-inline-block">{{
                shownItem.delivery_date
                  | readableDate(false, 'yyyy-MM-dd HH:mm:ss')
              }}</span>
            </td>
            <!-- Order Type -->
            <td>Materials and Supplies</td>
            <td v-if="status === STATUS.QUOTE_SENT.value">
              <div class="d-flex align-items-center text-info">
                {{ quotationsCount(shownItem) }}
              </div>
            </td>
            <!-- Action Btns -->
            <td class="text-info py-4">
              <div class="d-flex">
                <router-link
                  v-if="
                    [
                      STATUS.QUOTE_SENT.value,
                      STATUS.QUOTE_APPROVED.value,
                    ].includes(status)
                  "
                  :to="{
                    name: 'QuoteRequestList',
                    params: { id: shownItem.id },
                  }"
                  class="
                    order-table-action
                    text-decoration-none
                    d-flex
                    align-items-center
                    mr-2
                  "
                >
                  <AppTooltip tooltipText="View Order">
                    <AppIcon name="document"></AppIcon>
                  </AppTooltip>
                </router-link>
                <template v-else>
                  <!-- Super admin actions -->
                  <template v-if="user.persona === 'super'">
                    <router-link
                      :to="{
                        name: 'OrderView',
                        params: { id: shownItem.id },
                      }"
                      class="
                        order-table-action
                        text-decoration-none
                        d-flex
                        align-items-center
                        mr-2
                      "
                    >
                      <AppTooltip tooltipText="View Order">
                        <AppIcon name="document"></AppIcon>
                      </AppTooltip>
                    </router-link>
                    <router-link
                      v-if="status === 'approved'"
                      :to="{
                        name: 'OrderView',
                        params: { id: shownItem.id },
                        query: { view_quote: true },
                      }"
                      class="
                        order-table-action
                        text-decoration-none
                        d-flex
                        align-items-center
                        mr-2
                      "
                    >
                      <AppTooltip tooltipText="View RFQ">
                        <AppIcon name="document"></AppIcon>
                      </AppTooltip>
                    </router-link>
                  </template>
                  <template v-else>
                    <router-link
                      :to="{
                        name: 'OrderView',
                        params: { id: shownItem.id },
                      }"
                      class="
                        order-table-action
                        text-decoration-none
                        d-flex
                        align-items-center
                        mr-2
                      "
                    >
                      <AppTooltip tooltipText="View Order">
                        <AppIcon name="document"></AppIcon>
                      </AppTooltip>
                    </router-link>
                  </template>
                </template>
                <div
                  class="
                    order-table-action
                    text-decoration-none
                    d-flex
                    align-items-center
                    mr-2
                    hoverable
                  "
                  @click="confirmCloneOrder(shownItem.id)"
                >
                  <AppTooltip tooltipText="Clone Order">
                    <AppIcon name="copy"></AppIcon>
                  </AppTooltip>
                </div>

                <template
                  v-if="
                    isAdminOrOwner && status === STATUS.REQUEST_PENDING.value
                  "
                >
                  <div
                    class="
                      order-table-action
                      text-decoration-none
                      d-flex
                      align-items-center
                      mr-2
                      hoverable
                      text-success
                    "
                    @click="confirmApproveOrder(shownItem.id)"
                  >
                    <AppTooltip tooltipText="Approve RFQ">
                      <AppIcon name="checkmark_filled"></AppIcon>
                    </AppTooltip>
                  </div>
                  <div
                    class="
                      order-table-action
                      text-decoration-none
                      d-flex
                      align-items-center
                      mr-2
                      hoverable
                      text-danger
                    "
                    @click="confirmRejectOrder(shownItem.id)"
                  >
                    <AppTooltip tooltipText="Reject RFQ">
                      <AppIcon name="close_filled"></AppIcon>
                    </AppTooltip>
                  </div>
                </template>
              </div>
            </td>
          </template>
        </AppTable>
      </div>
      <!-- Mobile View -->
      <div class="d-md-none" v-if="items.data">
        <template v-if="items.data.length > 0">
          <AppCard
            v-for="(shownItem, index) in items.data"
            :key="`order-item-${index}`"
            class="p-3 mb-3"
            @click="redirectToDetails(shownItem.id)"
          >
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <!-- Project Name -->
              <span
                v-if="
                  status === STATUS.REQUEST_PENDING.value ||
                  status === STATUS.REQUEST_APPROVED.value
                "
                class="font-weight-6 w-100 text-truncate"
              >
                {{ shownItem.project_name }}
              </span>
              <!-- <span v-else class="font-weight-6 w-100 text-truncate">
                {{ shownItem.order.project_name }}
              </span> -->
            </div>
            <div class="d-flex align-items-center">
              <div>
                <AppMediaViewer
                  width="45px"
                  aspectRatio="1/1"
                  class="rounded-circle"
                  :src="
                    shownItem.user.information
                      ? shownItem.user.information.profile_img
                      : ''
                  "
                ></AppMediaViewer>
              </div>
              <div class="ml-2 d-flex flex-column">
                <!-- User Name -->
                <span class="">
                  {{ shownItem.user.information | userFullName }}
                </span>
                <!-- User Company -->
                <span class="text-body-2">
                  {{ shownItem.user.information | userCompany }}
                </span>
              </div>
            </div>
            <!-- RFQ -->
            <div class="row mt-3">
              <div class="col-6 font-weight-3">RFQ</div>
              <div class="col-6">{{ shownItem.id | rfqFormat }}</div>
            </div>
            <!-- PO NUMBER -->
            <div class="row mt-3">
              <div class="col-6 font-weight-3">PO Number</div>
              <div class="col-6">{{ shownItem.po_number || 'N/A' }}</div>
            </div>
            <!-- End Bidding Date -->
            <div
              v-if="
                status === STATUS.REQUEST_PENDING.value ||
                status === STATUS.REQUEST_APPROVED.value
              "
              class="row mt-3"
            >
              <div class="col-6 font-weight-3">Bidding Date End</div>
              <div class="col-6">
                <span>
                  {{ shownItem.bidding_end_date | readableDate(false, true) }}
                </span>
                <span class="d-inline-block text-gray">
                  {{ shownItem.bidding_end_date | readableTime(true) }}
                </span>
              </div>
            </div>
            <!-- Delivery Date Date -->
            <div v-else class="row mt-3">
              <div class="col-6 font-weight-3">Delivery Date</div>
              <div class="col-6">
                {{ shownItem.delivery_date | readableDate(false, true) }}
              </div>
            </div>

            <div class="d-flex mt-2" v-if="status === STATUS.REQUEST_APPROVED.value">
              <router-link
                class="
                  dashboard-card-action-request
                  p-1
                  d-flex
                  align-items-center
                  justify-content-center
                  text-info
                  flex-grow-1
                "
                :to="{ name: 'OrderView', params: { id: shownItem.id } }"
              >
                <AppIcon name="document"></AppIcon>

                <label class="m-0 ml-1 font-weight-normal">View Order</label>
              </router-link>
              <router-link
                class="
                  dashboard-card-action-request
                  p-1
                  d-flex
                  align-items-center
                  justify-content-center
                  text-info
                  flex-grow-1
                "
                :to="{
                  name: 'OrderView',
                  params: { id: shownItem.id },
                  query: { view_quote: true },
                }"
              >
                <AppIcon name="document"></AppIcon>

                <label class="m-0 ml-1 font-weight-normal">View RFQ</label>
              </router-link>
            </div>
          </AppCard>
          <AppPagination
            v-if="items.data"
            hideDetails
            centerPagination
            itemsPerPage="10"
            :currentPage="items.current_page"
            :total="items.total"
            @pageChange="(page) => $emit('pageChange', page)"
          ></AppPagination>
        </template>
        <template v-else>
          <h4 class="text-center font-weight-1">No data available</h4>
        </template>
      </div>
    </div>

    <ActionModal
      v-model="confirmModal"
      @confirm="runAction"
      @cancel="selectedOrderId = null"
    >
      <template v-slot:title>{{ actionTitle }}</template>
      <template v-slot:description> Click confirm to proceed </template>
    </ActionModal>

    <SuccessModal v-model="successModal" @confirm="confirmEvent()">
      <template v-slot:description>{{ successTitle }}</template>
    </SuccessModal>
  </div>
</template>

<script>
import placeholder from '@/assets/pocketyard_logo_black.svg';
import AppTable from '@/shared/elements/AppTable.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import { STATUS } from '@/shared/constants/OrderConstants';
import AppCard from '../../../../shared/elements/AppCard.vue';
import AppMediaViewer from '../../../../shared/elements/AppMediaViewer.vue';
import AppPagination from '../../../../shared/elements/AppPagination.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import Orders from '@/shared/api/Orders.js';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import AppLoading from '@/shared/elements/AppLoading.vue';
import AppTooltip from '../../../../shared/elements/AppTooltip.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'BuyerOrderRequest',

  components: {
    AppTable,
    AppIcon,
    AppCard,
    AppMediaViewer,
    AppPagination,
    ActionModal,
    SuccessModal,
    AppLoading,
    AppTooltip,
  },

  mixins: [_readableDateTime, _rfqFormat],

  props: {
    items: { type: Object, default: () => ({}) },
    status: { type: String, default: 'pending' },
    sort: { type: Array, default: () => [] },
  },

  data() {
    let headers = null;
    if (
      this.status === STATUS.REQUEST_PENDING.value ||
      this.status === STATUS.REQUEST_APPROVED.value
    ) {
      headers = [
        {
          text: 'RFQ ID',
          value: 'rqf_num',
          sortable: false,
        },
        {
          text: 'PO',
          value: 'po_num',
          sortable: false,
        },
        {
          text: 'Project Name',
          value: 'order_project_name',
          desc: this.isDesc('order_project_name'),
        },
        {
          text: 'Order Date',
          value: 'order_created_at',
          desc: this.isDesc('order_created_at'),
        },
        {
          text: 'Bidding Date End',
          value: 'order_bidding_end_date',
          desc: this.isDesc('order_bidding_end_date'),
        },
        {
          text: 'Delivery Date',
          value: 'order_delivery_date',
          desc: this.isDesc('order_delivery_date'),
        },
        {
          text: 'Order Type',
          value: 'order_type',
        },
        {
          text: '',
          sortable: false,
        },
      ];
    } else if (this.status === STATUS.QUOTE_SENT.value) {
      headers = [
        {
          text: 'RFQ ID',
          value: 'rqf_num',
          sortable: false,
        },
        {
          text: 'PO',
          value: 'po_num',
          sortable: false,
        },
        {
          text: 'Project Name',
          value: 'order_project_name',
          sortable: false,
        },
        {
          text: 'Supplier',
          value: 'supplier',
          sortable: false,
        },
        {
          text: 'Approved Date',
          value: 'order_approved_at',
          desc: this.isDesc('order_approved_at'),
        },
        {
          text: 'Delivery Date',
          value: 'order_delivery_date',
          desc: this.isDesc('order_delivery_date'),
        },
        {
          text: 'Order Type',
          value: 'order_type',
        },
        {
          text: 'Quotations',
          value: 'quotations_count',
        },
        {
          text: '',
          sortable: false,
        },
      ];
    } else {
      headers = [
        {
          text: 'RFQ ID',
          value: 'rqf_num',
          sortable: false,
        },
        {
          text: 'PO',
          value: 'po_num',
          sortable: false,
        },
        {
          text: 'Project Name',
          value: 'order_project_name',
          sortable: false,
        },
        {
          text: 'Supplier',
          value: 'supplier',
          sortable: false,
        },
        {
          text: 'Approved Date',
          value: 'order_approved_at',
          desc: this.isDesc('order_approved_at'),
        },
        {
          text: 'Delivery Date',
          value: 'order_delivery_date',
          desc: this.isDesc('order_delivery_date'),
        },
        {
          text: 'Order Type',
          value: 'order_type',
        },
        {
          text: '',
          sortable: false,
        },
      ];
    }

    return {
      successModal: false,
      successTitle: '',
      confirmModal: false,
      selectedOrderId: null,
      STATUS,
      placeholder,
      loading: false,
      currentPage: 1,
      sorts: this.sort,
      header: headers,
      actionTitle: '',
      action: '',
      emitEvent: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['user']),

    isAdminOrOwner() {
      let groups = this.user.groups;

      if (groups === null || groups.length === 0 || !groups) {
        return false;
      }

      return groups.some((group) => {
        return ['Administrator', 'Owner'].includes(group.name);
      });
    },
  },

  filters: {
    userFullName(value) {
      if (value && (value.first_name || value.last_name)) {
        return `${value.first_name} ${value.last_name} `;
      } else {
        return 'N/A';
      }
    },
    userCompany(value) {
      if (value && value.company_name) {
        return value.company_name;
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    confirmEvent() {
      this.successModal = false;
      if (this.emitEvent) {
        this.$emit(this.emitEvent);
      }
    },
    confirmCloneOrder(id) {
      this.actionTitle = 'Are you sure you want to clone this order?';
      this.successTitle = this.isAdminOrOwner
        ? 'Your order has been cloned successfully. You can start receiving quotation now.'
        : 'Your order has been cloned successfully and awaiting admin approval.';
      this.action = 'cloneOrder';
      this.confirmModal = true;
      this.selectedOrderId = id;
    },
    confirmApproveOrder(id) {
      this.actionTitle = 'Are you sure you want to approve this order?';
      this.successTitle =
        'Order has been approved successfully, please check the Approved RFQs';
      this.action = 'approveOrder';
      this.confirmModal = true;
      this.selectedOrderId = id;
    },
    confirmRejectOrder(id) {
      this.actionTitle = 'Are you sure you want to reject this order?';
      this.successTitle =
        'Order has been rejected successfully, please check the Rejected RFQs';
      this.action = 'rejectOrder';
      this.confirmModal = true;
      this.selectedOrderId = id;
    },

    async runAction() {
      if (!this.action || !this.selectedOrderId) {
        return;
      }

      await this[this.action]();
    },
    async cloneOrder() {
      if (this.selectedOrderId === null) {
        return;
      }

      this.loading = true;

      await Orders.cloneOrder(this.selectedOrderId).then(() => {
        this.loading = false;
        this.emitEvent = 'cloned-order';
        this.successModal = true;
      });
    },
    async approveOrder() {
      if (this.selectedOrderId === null) {
        return;
      }

      this.loading = true;

      await Orders.editOrder(this.selectedOrderId, { status: 'approved' }).then(
        () => {
          this.loading = false;
          this.emitEvent = 'approved-order';
          this.successModal = true;
        }
      );
    },
    async rejectOrder() {
      if (this.selectedOrderId === null) {
        return;
      }

      this.loading = true;

      await Orders.editOrder(this.selectedOrderId, { status: 'rejected' }).then(
        () => {
          this.loading = false;
          this.emitEvent = 'rejected-order';
          this.successModal = true;
        }
      );
    },
    sortBy({ item, desc }) {
      const existingIndex = this.sorts.findIndex(
        (value) => value.item === item
      );
      if (existingIndex < 0) {
        this.sorts.push({ item, orderBy: desc ? 'DESC' : 'ASC' });
      } else {
        this.sorts[existingIndex].orderBy = desc ? 'DESC' : 'ASC';
      }
      this.$emit('sort', this.sorts);
    },
    isDesc(value) {
      return this.sort.find((val) => val.item === value)?.orderBy === 'DESC';
    },
    redirectToDetails(id) {
      let routeObject = null;

      switch (this.status) {
        case STATUS.REQUEST_PENDING.value:
          routeObject = { name: 'OrderView', params: { id: id } };
          break;
        case STATUS.REQUEST_APPROVED.value:
          //Handled above
          return;

        case STATUS.QUOTE_SENT.value:
          routeObject = {
            name: 'QuoteRequestList',
            params: { id: id },
          };
          break;
        case STATUS.QUOTE_APPROVED.value:
          routeObject = {
            name: 'QuoteRequestList',
            params: { id: id },
          };
          break;
        case STATUS.COMPLETED.value:
          routeObject = { name: 'OrderView', params: { id: id } };
          break;
        default:
          routeObject = { name: 'OrderView', params: { id: id } };
          break;
      }

      this.$router.push(routeObject);
    },
    quotationsCount(shownItem) {
      const quotes = shownItem?.quotations?.length;

      if (!quotes) {
        return `0 Quotations`;
      }

      const str = `Quotation${quotes > 1 ? 's' : ''}`;

      return `${quotes} ${str}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.order-table {
  &-action {
    p {
      // display: none;
      max-width: 0;
      overflow: hidden;
      transition: max-width 0.5s ease-in-out;
      white-space: nowrap;
    }
    &:hover {
      p {
        max-width: 200px;
      }
    }
  }
}
</style>
