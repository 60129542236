<template>
  <div class="app-table d-flex flex-column">
    <table class="table table-borderless w-100 bg-white">
      <thead class="text-white">
        <tr>
          <th
            v-for="(header, index) in headerClone"
            class="align-baseline align-top align-text-top"
            :class="header.className || null"
            :key="`app-table-header-${header.value || header.text}-${index}`"
          >
            <p class="m-0 d-inline text-truncate">
              {{ header.text || header.value }}
            </p>
            <AppIcon
              v-if="header.sortable !== false"
              hoverable
              class="text-primary-light-1 d-inline"
              :name="header.desc ? 'caret_up' : 'caret_down'"
              @click="sort(header.value || header.text, !header.desc)"
            ></AppIcon>
          </th>
        </tr>
      </thead>
      <tr v-if="!items">
        <td class="font-weight-5 text-center p-3" :colspan="headers.length">
          No items to show
        </td>
      </tr>
      <tbody v-else>
        <slot name="items" :shownItems="displayItem">
          <template v-if="displayItem.length > 0">
            <tr
              v-for="(item, itemIndex) in displayItem"
              :key="`app-table-item-${itemIndex}`"
              :class="{ hoverable }"
              @click="$emit('itemClick', item)"
            >
              <slot name="item" :shownItem="item">
                <td
                  v-for="(property, propertyIndex) in headerClone"
                  :key="`app-table-item-${itemIndex}-${propertyIndex}`"
                  class="p-0"
                >
                  <slot
                    :name="`item-${property.value || propertyIndex}`"
                    :item="item"
                  >
                    <div class="p-3">
                      {{ item[property.value] || item[property.text] }}
                    </div>
                  </slot>
                </td>
              </slot>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="headerClone.length">
                <h4 class="text-center font-weight-1">No data available</h4>
              </td>
            </tr>
          </template>
        </slot>
      </tbody>
    </table>
    <AppPagination
      class="mt-auto p-2"
      :class="paginationClass"
      :currentPage="currentPage || currentPageClone"
      :itemsPerPage="itemsPerPage || items.length"
      :total="total || items.length"
      :hideDetails="paginationHideDetail"
      :centerPagination="centerPagination"
      @pageChange="pageChange"
    ></AppPagination>
  </div>
</template>

<script>
import AppIcon from './AppIcon.vue';
import AppPagination from './AppPagination.vue';
export default {
  components: { AppIcon, AppPagination },
  name: 'AppTable',

  props: {
    headers: { type: Array, default: () => [] },
    hoverable: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    itemsPerPage: { type: [String, Number], default: 10 },
    total: { type: [String, Number], default: 0 },
    currentPage: { type: [String, Number], default: 0 },
    paginationClass: { type: String, default: '' },
    paginationHideDetail: { type: Boolean, default: false },
    centerPagination: { type: Boolean, default: false },
  },

  data() {
    return {
      headerClone: this.headers,
      currentPageClone: this.currentPage || 1,
    };
  },

  computed: {
    displayItem() {
      if (this.currentPage) {
        return this.items;
      } else {
        const page = this.currentPage || this.currentPageClone;
        const start = this.itemsPerPage * (page - 1);
        const end = this.itemsPerPage * page;
        return this.items.slice(start, end);
      }
    },
  },

  methods: {
    // to be completed
    sort(item, desc) {
      const matchingHeader = (header) => {
        return header.value === item || header.text === item;
      };
      const newHeaderData = this.headerClone;
      newHeaderData.find(matchingHeader).desc = desc;
      this.headerClone = false;
      this.headerClone = newHeaderData;
      this.$emit('sort', { item, desc });
    },
    pageChange(newPage) {
      if (this.currentPage) {
        this.$emit('pageChange', newPage);
      } else {
        this.currentPageClone = newPage;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';
.app-table {
  table {
    thead {
      @extend .bg-black-light-2;
      tr,
      th {
        @extend .bg-black-light-2;
      }
    }
    tbody {
      tr {
        &:hover {
          @extend .bg-gray-light-4;
          td {
            @extend .bg-gray-light-4;
          }
        }
        td {
          vertical-align: middle;
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
  }
}
</style>
