<template>
  <div
    class="app-tooltip position-relative d-inline-flex justify-content-center"
  >
    <div class="app-tooltip-hoverable">
      <slot>
        <AppIcon name="help" class="d-inline text-gray-dark-3"></AppIcon>
      </slot>
    </div>
    <div
      class="app-tooltip-text"
      :style="{ '--arrow-color': `var(--${color})` }"
    >
      <!-- Text -->
      <p class="p-2 font-weight-3 mb-0" :class="`bg-${color}`">
        {{ tooltipText }}
      </p>
      <!-- Arrow Down -->
      <div class="d-flex justify-content-center">
        <div class="app-tooltip-arrow app-tooltip-arrow-down"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from './AppIcon.vue';
export default {
  name: 'AppTooltip',

  components: { AppIcon },

  props: {
    color: { type: String, default: 'primary-light-6  ' },
    tooltipText: { type: String, default: '' },
  },
};
</script>

<style lang="scss" scoped>
@import '../../scss/theme/breakpoints';

.app-tooltip {
  &:hover {
    .app-tooltip-text {
      display: block;
    }
  }
  &-text {
    display: none;
    position: absolute;
    bottom: 105%;
    white-space: nowrap;

    @include media-sm('below') {
      white-space: normal;
      width: 300px;
      max-width: 300px;
    }
    z-index: 99999;
  }

  &-arrow {
    width: 0;
    height: 0;
    &-up {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid var(--arrow-color);
    }
    &-down {
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid var(--arrow-color);
    }

    &-left {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid var(--arrow-color);
    }

    &-right {
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
      border-left: 60px solid var(--arrow-color);
    }
  }
}
</style>
