var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[(_vm.loading)?_c('AppLoading',{attrs:{"fillSpace":""}}):_c('div',[_c('div',{staticClass:"d-none d-lg-block"},[_c('AppTable',{staticClass:"h-100",attrs:{"headers":_vm.header,"items":_vm.items.data,"currentPage":_vm.items.current_page,"total":_vm.items.total},on:{"pageChange":function (page) { return _vm.$emit('pageChange', page); },"sort":_vm.sortBy},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var shownItem = ref.shownItem;
return [_c('td',[_vm._v(_vm._s(_vm._f("rfqFormat")(shownItem.id)))]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(shownItem.po_number || 'N/A')+" ")]),_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(shownItem.project_name)+" ")]),(
              _vm.status == _vm.STATUS.REQUEST_PENDING.value ||
              _vm.status == _vm.STATUS.REQUEST_APPROVED.value
            )?_c('td',[_c('span',[_vm._v(_vm._s(_vm._f("readableDate")(shownItem.created_at,false)))]),_c('span',{staticClass:"ml-1 text-gray"},[_vm._v(" "+_vm._s(_vm._f("readableTime")(shownItem.created_at))+" ")])]):_c('td',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"rounded-circle mr-3",attrs:{"src":require("@/assets/pocketyard_logo_black.svg"),"width":"30","height":"30"}}),_c('div',{staticStyle:{"width":"150px"}},[_c('p',{staticClass:"m-0 text-truncate"},[_vm._v(" "+_vm._s(_vm._f("userFullName")(shownItem.user.information))+" ")]),_c('p',{staticClass:"m-0 text-gray text-truncate"},[_vm._v(" "+_vm._s(_vm._f("userCompany")(shownItem.user.information))+" ")])])]),(
              _vm.status == _vm.STATUS.QUOTE_APPROVED.value ||
              _vm.status === _vm.STATUS.COMPLETED.value
            )?_c('td',[_vm._v(" "+_vm._s(_vm._f("readableDate")(shownItem.updated_at,false))+" ")]):_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm._f("readableDate")(shownItem.bidding_end_date,false, 'yyyy-MM-dd HH:mm:ss'))+" ")]),_c('span',{staticClass:"ml-1 d-inline-block text-gray"},[_vm._v(" "+_vm._s(_vm._f("readableTime")(shownItem.bidding_end_date,'yyyy-MM-dd HH:mm:ss'))+" ")])]),_c('td',[_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(_vm._f("readableDate")(shownItem.delivery_date,false, 'yyyy-MM-dd HH:mm:ss')))])]),_c('td',[_vm._v("Materials and Supplies")]),(_vm.status === _vm.STATUS.QUOTE_SENT.value)?_c('td',[_c('div',{staticClass:"d-flex align-items-center text-info"},[_vm._v(" "+_vm._s(_vm.quotationsCount(shownItem))+" ")])]):_vm._e(),_c('td',{staticClass:"text-info py-4"},[_c('div',{staticClass:"d-flex"},[(
                  [
                    _vm.STATUS.QUOTE_SENT.value,
                    _vm.STATUS.QUOTE_APPROVED.value ].includes(_vm.status)
                )?_c('router-link',{staticClass:"\n                  order-table-action\n                  text-decoration-none\n                  d-flex\n                  align-items-center\n                  mr-2\n                ",attrs:{"to":{
                  name: 'QuoteRequestList',
                  params: { id: shownItem.id },
                }}},[_c('AppTooltip',{attrs:{"tooltipText":"View Order"}},[_c('AppIcon',{attrs:{"name":"document"}})],1)],1):[(_vm.user.persona === 'super')?[_c('router-link',{staticClass:"\n                      order-table-action\n                      text-decoration-none\n                      d-flex\n                      align-items-center\n                      mr-2\n                    ",attrs:{"to":{
                      name: 'OrderView',
                      params: { id: shownItem.id },
                    }}},[_c('AppTooltip',{attrs:{"tooltipText":"View Order"}},[_c('AppIcon',{attrs:{"name":"document"}})],1)],1),(_vm.status === 'approved')?_c('router-link',{staticClass:"\n                      order-table-action\n                      text-decoration-none\n                      d-flex\n                      align-items-center\n                      mr-2\n                    ",attrs:{"to":{
                      name: 'OrderView',
                      params: { id: shownItem.id },
                      query: { view_quote: true },
                    }}},[_c('AppTooltip',{attrs:{"tooltipText":"View RFQ"}},[_c('AppIcon',{attrs:{"name":"document"}})],1)],1):_vm._e()]:[_c('router-link',{staticClass:"\n                      order-table-action\n                      text-decoration-none\n                      d-flex\n                      align-items-center\n                      mr-2\n                    ",attrs:{"to":{
                      name: 'OrderView',
                      params: { id: shownItem.id },
                    }}},[_c('AppTooltip',{attrs:{"tooltipText":"View Order"}},[_c('AppIcon',{attrs:{"name":"document"}})],1)],1)]],_c('div',{staticClass:"\n                  order-table-action\n                  text-decoration-none\n                  d-flex\n                  align-items-center\n                  mr-2\n                  hoverable\n                ",on:{"click":function($event){return _vm.confirmCloneOrder(shownItem.id)}}},[_c('AppTooltip',{attrs:{"tooltipText":"Clone Order"}},[_c('AppIcon',{attrs:{"name":"copy"}})],1)],1),(
                  _vm.isAdminOrOwner && _vm.status === _vm.STATUS.REQUEST_PENDING.value
                )?[_c('div',{staticClass:"\n                    order-table-action\n                    text-decoration-none\n                    d-flex\n                    align-items-center\n                    mr-2\n                    hoverable\n                    text-success\n                  ",on:{"click":function($event){return _vm.confirmApproveOrder(shownItem.id)}}},[_c('AppTooltip',{attrs:{"tooltipText":"Approve RFQ"}},[_c('AppIcon',{attrs:{"name":"checkmark_filled"}})],1)],1),_c('div',{staticClass:"\n                    order-table-action\n                    text-decoration-none\n                    d-flex\n                    align-items-center\n                    mr-2\n                    hoverable\n                    text-danger\n                  ",on:{"click":function($event){return _vm.confirmRejectOrder(shownItem.id)}}},[_c('AppTooltip',{attrs:{"tooltipText":"Reject RFQ"}},[_c('AppIcon',{attrs:{"name":"close_filled"}})],1)],1)]:_vm._e()],2)])]}}])})],1),(_vm.items.data)?_c('div',{staticClass:"d-md-none"},[(_vm.items.data.length > 0)?[_vm._l((_vm.items.data),function(shownItem,index){return _c('AppCard',{key:("order-item-" + index),staticClass:"p-3 mb-3",on:{"click":function($event){return _vm.redirectToDetails(shownItem.id)}}},[_c('div',{staticClass:"mb-3 d-flex align-items-center justify-content-between"},[(
                _vm.status === _vm.STATUS.REQUEST_PENDING.value ||
                _vm.status === _vm.STATUS.REQUEST_APPROVED.value
              )?_c('span',{staticClass:"font-weight-6 w-100 text-truncate"},[_vm._v(" "+_vm._s(shownItem.project_name)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('AppMediaViewer',{staticClass:"rounded-circle",attrs:{"width":"45px","aspectRatio":"1/1","src":shownItem.user.information
                    ? shownItem.user.information.profile_img
                    : ''}})],1),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('span',{},[_vm._v(" "+_vm._s(_vm._f("userFullName")(shownItem.user.information))+" ")]),_c('span',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm._f("userCompany")(shownItem.user.information))+" ")])])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6 font-weight-3"},[_vm._v("RFQ")]),_c('div',{staticClass:"col-6"},[_vm._v(_vm._s(_vm._f("rfqFormat")(shownItem.id)))])]),_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6 font-weight-3"},[_vm._v("PO Number")]),_c('div',{staticClass:"col-6"},[_vm._v(_vm._s(shownItem.po_number || 'N/A'))])]),(
              _vm.status === _vm.STATUS.REQUEST_PENDING.value ||
              _vm.status === _vm.STATUS.REQUEST_APPROVED.value
            )?_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6 font-weight-3"},[_vm._v("Bidding Date End")]),_c('div',{staticClass:"col-6"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("readableDate")(shownItem.bidding_end_date,false, true))+" ")]),_c('span',{staticClass:"d-inline-block text-gray"},[_vm._v(" "+_vm._s(_vm._f("readableTime")(shownItem.bidding_end_date,true))+" ")])])]):_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-6 font-weight-3"},[_vm._v("Delivery Date")]),_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(_vm._f("readableDate")(shownItem.delivery_date,false, true))+" ")])]),(_vm.status === _vm.STATUS.REQUEST_APPROVED.value)?_c('div',{staticClass:"d-flex mt-2"},[_c('router-link',{staticClass:"\n                dashboard-card-action-request\n                p-1\n                d-flex\n                align-items-center\n                justify-content-center\n                text-info\n                flex-grow-1\n              ",attrs:{"to":{ name: 'OrderView', params: { id: shownItem.id } }}},[_c('AppIcon',{attrs:{"name":"document"}}),_c('label',{staticClass:"m-0 ml-1 font-weight-normal"},[_vm._v("View Order")])],1),_c('router-link',{staticClass:"\n                dashboard-card-action-request\n                p-1\n                d-flex\n                align-items-center\n                justify-content-center\n                text-info\n                flex-grow-1\n              ",attrs:{"to":{
                name: 'OrderView',
                params: { id: shownItem.id },
                query: { view_quote: true },
              }}},[_c('AppIcon',{attrs:{"name":"document"}}),_c('label',{staticClass:"m-0 ml-1 font-weight-normal"},[_vm._v("View RFQ")])],1)],1):_vm._e()])}),(_vm.items.data)?_c('AppPagination',{attrs:{"hideDetails":"","centerPagination":"","itemsPerPage":"10","currentPage":_vm.items.current_page,"total":_vm.items.total},on:{"pageChange":function (page) { return _vm.$emit('pageChange', page); }}}):_vm._e()]:[_c('h4',{staticClass:"text-center font-weight-1"},[_vm._v("No data available")])]],2):_vm._e()]),_c('ActionModal',{on:{"confirm":_vm.runAction,"cancel":function($event){_vm.selectedOrderId = null}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.actionTitle))]},proxy:true},{key:"description",fn:function(){return [_vm._v(" Click confirm to proceed ")]},proxy:true}]),model:{value:(_vm.confirmModal),callback:function ($$v) {_vm.confirmModal=$$v},expression:"confirmModal"}}),_c('SuccessModal',{on:{"confirm":function($event){return _vm.confirmEvent()}},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(_vm._s(_vm.successTitle))]},proxy:true}]),model:{value:(_vm.successModal),callback:function ($$v) {_vm.successModal=$$v},expression:"successModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }