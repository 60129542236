<template>
  <div class="app-pagination">
    <p v-if="!hideDetails" class="app-pagination-label m-0">
      {{ dataStart }} - {{ dataEnd }} of {{ total }} results
    </p>
    <div
      class="app-pagination-actions"
      :class="{ 'mr-auto': centerPagination }"
    >
      <!-- Start Btn -->
      <AppBtn
        text
        color="black"
        class="pl-0 pr-0"
        :disabled="currentPage === 1"
        @click="gotoStart"
      >
        <AppIcon name="chevron_left" class="d-inline mr-n3"></AppIcon>
        <AppIcon name="chevron_left" class="d-inline"></AppIcon>
      </AppBtn>
      <!-- Previous Btn -->
      <AppBtn
        text
        color="black"
        class="pl-0 pr-0 mr-2"
        :disabled="currentPage === 1"
        @click="gotoPrevious"
      >
        <AppIcon name="chevron_left"></AppIcon>
      </AppBtn>
      <!-- first page btn -->
      <div v-if="offset > 0" class="d-inline">
        <AppBtn
          text
          color="black"
          class="pl-2 pr-2 pl-md-3 pr-md-3"
          @click="gotoStart"
        >
          1
        </AppBtn>
        <span v-if="offset > 1">...</span>
      </div>
      <!-- Pages Btns -->
      <AppBtn
        v-for="page in displayPages"
        :key="`app-pagination-page-${page}`"
        :text="page !== currentPage"
        :color="page !== currentPage ? 'black' : 'primary'"
        class="m-0 pl-2 pr-2 pl-md-3 pr-md-3"
        @click="gotoPage(page)"
      >
        {{ page }}
      </AppBtn>
      <!-- last page btn -->
      <div
        v-if="currentPage < pages - 1 && pages > pageVisible"
        class="d-inline"
      >
        <span v-if="currentPage < pages - 2">...</span>
        <AppBtn
          text
          color="black"
          class="pl-2 pr-2 pl-md-3 pr-md-3"
          @click="gotoEnd()"
        >
          {{ pages }}
        </AppBtn>
      </div>
      <!-- Next Btn -->
      <AppBtn
        text
        color="black"
        class="pl-0 pr-0 ml-2"
        :disabled="currentPage === pages"
        @click="gotoNext"
      >
        <AppIcon name="chevron_right"></AppIcon>
      </AppBtn>
      <!-- End tn -->
      <AppBtn
        text
        color="black"
        class="pl-0 pr-0"
        :disabled="currentPage === pages"
        @click="gotoEnd"
      >
        <AppIcon name="chevron_right" class="d-inline"></AppIcon>
        <AppIcon name="chevron_right" class="ml-n3 d-inline"></AppIcon>
      </AppBtn>
    </div>
  </div>
</template>

<script>
import AppBtn from './AppBtn.vue';
import AppIcon from './AppIcon.vue';
export default {
  components: { AppBtn, AppIcon },
  name: 'AppPagination',

  props: {
    total: { type: [String, Number], default: 0 },
    itemsPerPage: { type: [String, Number], default: 0 },
    currentPage: { type: [String, Number], default: 0 },
    pageVisible: { type: [String, Number], default: 3 },
    hideDetails: { type: Boolean, default: false },
    centerPagination: { type: Boolean, default: false },
  },

  data() {
    return {
      pageStart: 1,
      pageEnd: this.pageVisible,
    };
  },

  computed: {
    offset() {
      let offset = 0;
      if (
        this.currentPage + 1 === this.pages &&
        this.pages > this.pageVisible
      ) {
        offset = this.currentPage + 1 - this.pageVisible;
      } else if (
        this.currentPage + 1 > this.pageVisible &&
        this.currentPage < this.pages
      ) {
        offset = this.currentPage - this.pageVisible + 1;
      } else if (this.currentPage > this.pageVisible) {
        offset = this.currentPage - this.pageVisible;
      }
      return offset;
    },
    dataStart() {
      const page = this.currentPage;
      const start = this.total > 0 ? this.itemsPerPage * (page - 1) + 1 : 0;
      return start;
    },
    dataEnd() {
      const page = this.currentPage;
      const possibleEnding = this.itemsPerPage * page;
      return possibleEnding < this.total ? possibleEnding : this.total;
    },
    pages() {
      return Math.ceil(this.total / this.itemsPerPage);
    },
    displayPages() {
      const pages = [];
      const numberOfPagesToDisplay =
        this.pages < this.pageVisible ? this.pages : this.pageVisible;
      for (let i = 0; i < numberOfPagesToDisplay; i++) {
        const page = i + 1 + this.offset;
        pages.push(page);
      }
      return pages;
    },
  },

  // Catch if when the max page changes and the current page
  // exceeds it
  watch: {
    pages(pages) {
      if (parseInt(this.currentPage) > parseInt(pages)) {
        this.$emit('pageChange', pages);
      } else if (parseInt(this.currentPage) === 0 && pages > 0) {
        this.$emit('pageChange', 1);
      }
    },
  },

  methods: {
    gotoNext() {
      if (this.currentPage < this.pages) {
        this.$emit('pageChange', this.currentPage + 1);
        // this.currentPage += 1;
        // if (this.currentPage > this.displayPages[this.pageVisible - 1]) {
        //   this.offset += 1;
        // }
      }
    },
    gotoPrevious() {
      if (this.currentPage > 1) {
        this.$emit('pageChange', this.currentPage - 1);
        // this.currentPage -= 1;
        // if (this.currentPage < this.displayPages[0]) {
        //   this.offset -= 1;
        // }
      }
    },
    gotoPage(page) {
      // this.currentPage = page;
      this.$emit('pageChange', page);
    },

    gotoStart() {
      this.$emit('pageChange', 1);
      // this.currentPage = 1;
      // this.offset = 0;
    },
    gotoEnd() {
      this.$emit('pageChange', this.pages);
      // this.currentPage = this.pages;
      // this.offset = this.pages - this.pageVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';

.app-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.app-pagination-actions {
  margin-top: 20px;
}
</style>
