<template>
  <LayoutBase
    :routes="routes"
    :hideTopBar="hideTopBar"
    :sidebarActiveItem="sidebarActiveItem"
  >
    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>

    <template v-slot:topbar-prepend-icons>
      <div class="cart-main">
        <div v-if="totalCart > 0" class="cart-main-notify">
          <div
            class="
              cart-main-notify-icon
              rounded-circle
              bg-success
              text-white text-center
            "
          >
            {{ totalCart }}
          </div>
        </div>

        <AppIcon
          hoverable
          name="shopping_cart"
          class="m-2 ml-3 mr-md-5"
          @click="showCart = true"
        >
        </AppIcon>
      </div>
      <CartDrawer v-model="showCart"></CartDrawer>
    </template>
  </LayoutBase>
</template>

<script>
import { group_names } from '@/shared/constants/PersonaConstants';
import LayoutBase from '../LayoutBase.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import CartDrawer from '../../Cart/CartDrawer.vue';
import Notifications from '@/shared/api/Notifications';
import { mapGetters } from 'vuex';

export default {
  components: { LayoutBase, AppIcon, CartDrawer },
  props: {
    sidebarActiveItem: { default: false },
    hideTopBar: { type: Boolean, default: false },
  },
  data() {
    return {
      notifications: {
        dashboard: {},
        order: {},
      },
      showCart: false,
    };
  },

  computed: {
    ...mapGetters('cart', ['active_cart']),
    totalCart() {
      return this.active_cart.reduce(
        (total, cart) => total + cart.items.length,
        0
      );
    },
    routes() {
      const { owner, admin, buyer, estimator, superintendent, laborer } =
        group_names;
      return [
        {
          route: '/dashboard',
          exact: false,
          text: 'Dashboard',
          icon: 'home',
          notifications: 0,
          // notifications: this.notifications.dashboard?.total || 0,
          activeName: 'dashboard',
          allowedGroups: [
            owner,
            admin,
            buyer,
            estimator,
            superintendent,
            laborer,
          ],
        },
        {
          route: '/product',
          exact: false,
          text: 'Materials & Supplies',
          icon: 'shopping_catalog',
          notifications: 0,
          activeName: 'product',
          allowedGroups: [
            owner,
            admin,
            buyer,
            estimator,
            superintendent,
            laborer,
          ],
        },
        {
          route: '/order',
          exact: false,
          text: 'Orders',
          icon: 'catalog',
          activeName: 'order',
          notifications: 0,
          // notifications: this.notifications.order?.total || 0,
          allowedGroups: [
            owner,
            admin,
            buyer,
            estimator,
            superintendent,
            laborer,
          ],
          subRoutes: [
            {
              route: '/request',
              overrideParentRoute: '/order',
              text: 'Pending RFQs',
              exact: true,
              activeName: 'request',
            },
            {
              route: '/approved',
              overrideParentRoute: '/order',
              text: 'RFQs',
              exact: true,
              activeName: 'approved',
            },
            {
              route: '/quoted',
              overrideParentRoute: '/order',
              text: 'Pending Orders',
              exact: true,
              activeName: 'quoted',
            },
            {
              route: '/accepted',
              overrideParentRoute: '/order',
              text: 'Accepted Orders',
              exact: true,
              activeName: 'accepted',
            },
            {
              route: '/completed',
              overrideParentRoute: '/order',
              text: 'Completed Orders',
              exact: true,
              activeName: 'completed',
            },
            {
              route: '/rejected',
              overrideParentRoute: '/order',
              text: 'Rejected RFQs',
              exact: true,
              activeName: 'rejected',
            },
            // {
            //   route: '/rejected',
            //   overrideParentRoute: '/quote',
            //   text: 'Rejected Orders',
            //   exact: true,
            //   activeName: 'rejected',
            // },
          ],
        },
        // {
        //   route: '/order/admin_approval',
        //   exact: false,
        //   text: 'Pending RFQs',
        //   icon: 'catalog',
        //   notifications: 0,
        //   allowedGroups: [owner, admin],
        //   activeName: 'admin_approval',
        // },
        {
          route: '/company/member',
          exact: false,
          text: 'Members',
          icon: 'group',
          notifications: 0,
          allowedGroups: [owner, admin],
          activeName: 'member',
        },
        {
          route: '/favorites',
          exact: false,
          text: 'Favorites',
          icon: 'favorite',
          notifications: 0,
          allowedGroups: [owner, buyer, admin],
          activeName: 'favorites',
        },
      ];
    },
  },

  methods: {
    async fetchNotifications() {
      const response = await Notifications.getNotifications();
      this.notifications.dashboard = response.data;
      this.notifications.order = response.data;
    },
  },

  mounted() {
    this.fetchNotifications();
  },
};
</script>

<style lang="scss" scoped>
.cart-main {
  position: relative;
  &-notify {
    position: absolute;
    right: 24px;

    &-icon {
      font-size: 0.8em;
      margin: 2px;
      height: 24px;
      width: 24px;
    }
  }
}
</style>
